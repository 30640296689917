import { useContext } from 'react';
import Carousel from 'components/Carousel';
import { Section, Title } from 'components/Structural';
import Banner from 'components/InteractiveProductBanner';
import { useHistory } from 'react-router-dom';
import { useAnalyticsV2ProviderConsumer } from 'providers/AnalyticsV2Provider';
import { useRemoteConfigConsumer } from 'providers/RemoteConfigProvider';
import { AllowedChannelsContext } from 'providers/AllowedChannelsProvider';
import ROUTES from 'routes/routes';
import BannerResid from 'assets/images/banner-resid.png';
import BannerAuto from 'assets/images/banner-auto.png';
import BannerOdonto from 'assets/images/banner-odonto.png';
import BannerBemEstar from 'assets/images/banner-bem-estar.png';
import BannerCarglass from 'assets/images/banner-carglass.png';
import BannerPet from 'assets/images/banner-pet.png';
import { LazyLoad } from 'components';
import { useCMSProviderConsumer } from 'providers/CMSProvider';

export default function PartnersSection() {
  const { isWelfareE2EAllowed, isPetE2EAllowed, isCarglassAllowed } = useContext(AllowedChannelsContext);
  const history = useHistory();
  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();
  const { pageContent } = useCMSProviderConsumer();

  const handleClickProductCard = ({ productName, redirectionPath }) => {
    dispatchAnalyticsEvent('HOME', 'A7', [productName]);
    history.push(redirectionPath);
  };

  const products = [
    {
      image: BannerResid,
      title: 'seguro residencial',
      icon: 'itaufonts_seguro_residencia',
      notificationText: pageContent?.discount && 'promoção',
      onClick: () =>
        handleClickProductCard({ productName: 'seguro residencial', redirectionPath: ROUTES.LANDING_RESID }),
    },
    {
      image: BannerAuto,
      title: 'seguro auto',
      icon: 'itaufonts_seguro_auto',
      onClick: () => handleClickProductCard({ productName: 'seguro auto', redirectionPath: ROUTES.LANDING_AUTO }),
    },
    {
      image: BannerOdonto,
      title: 'plano odonto',
      icon: 'itaufonts_sorriso',
      onClick: () => handleClickProductCard({ productName: 'plano odonto', redirectionPath: ROUTES.LANDING_ODONTO }),
    },
  ];

  if (isWelfareE2EAllowed) {
    products.push({
      image: BannerBemEstar,
      title: 'assistência bem-estar',
      icon: 'itaufonts_seguro_vida',
      onClick: () =>
        handleClickProductCard({ productName: 'assistência bem-estar', redirectionPath: ROUTES.LANDING_WELFARE }),
    });
  }

  if (isPetE2EAllowed) {
    products.push({
      image: BannerPet,
      title: 'assistência pet',
      icon: 'itaufonts_outline_seguro_pet',
      onClick: () => handleClickProductCard({ productName: 'assistência pet', redirectionPath: ROUTES.LANDING_PET }),
    });
  }

  if (isCarglassAllowed) {
    products.push({
      image: BannerCarglass,
      title: 'serviços automotivos',
      icon: 'itaufonts_seguro_auto',
      onClick: () => history.push('/carglass'),
    });
  }

  function CarouselItems() {
    return products.map((item, index) => <Banner key={index} item={item} />);
  }

  return (
    <Section backgroundColor="#EFE9E5">
      <Title text="O jeito **Itaú** de **cuidar** de **você**. Escolha o que te faz bem: " />
      <LazyLoad>
        <Carousel getElements={CarouselItems} />
      </LazyLoad>
    </Section>
  );
}
