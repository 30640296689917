import React, { useEffect, useState } from 'react';
import config from 'config';
import Structural from 'components/Structural';
import RecaptchaProvider from 'providers/RecaptchaProvider';
import { useAnalyticsV2ProviderConsumer } from 'providers/AnalyticsV2Provider';
import { ProductSuggestionSection } from 'layout/ProductSuggestionSection';
import { useRemoteConfigConsumer } from 'providers/RemoteConfigProvider';
import { useCMSProviderConsumer } from 'providers/CMSProvider';
import { useCashbackProviderConsumer } from 'providers/CashbackProvider';

import CrossfadeComponent from 'components/Structural/CrossfadeImages/CrossfadeComponent/CrossfadeComponent';
import { LazyLoad } from 'components';
import logo from 'assets/images/home-01.png';
import AdvisorySection from './components/AdvisorySection';
import DifferentialsSection from './components/DifferentialsSection';
import PartnersSection from './components/PartnersSection';
import ProductShowcaseSection from './components/ProductShowcaseSection';
import JumperSection from './components/JumperSection';
import ContactFormSection from './components/ContactFormSection';
import { ItauExclusivesSection } from './components/ItauExclusivesSection';

export default function NewHome() {
  const { ADVISORY, PRODUCT_SUGGESTION, ITAU_EXCLUSIVES_SECTION } = config;
  const remoteConfig = useRemoteConfigConsumer();
  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();
  const { pageContent } = useCMSProviderConsumer();
  const { cashbackIsEnabled } = useCashbackProviderConsumer();
  const [content, setContent] = useState(false);

  function ChooseBanner() {
    const components = [];

    if (content === 'img') {
      components.push([<CrossfadeComponent image={logo} />]);
      return components;
    }

    content.map(carouselItens => {
      const carrosselItem = carouselItens.carrossel_item;
      if (carrosselItem.variable_remote_config && !remoteConfig[carrosselItem.variable_remote_config].enabled) return;
      const cashback = carrosselItem?.product && cashbackIsEnabled(carrosselItem?.product);
      return components.push([
        <CrossfadeComponent
          image={carrosselItem.banner_image.path}
          icon={carrosselItem.icon_banner}
          subtitle={carrosselItem.subtitle}
          title={carrosselItem.title}
          buttonsList={carrosselItem.buttons}
          typeCard={carrosselItem.variable_remote_config}
          textColor={carrosselItem.text_color}
          className={cashback ? 'cashback' : ''}
        />,
      ]);
    });

    return components;
  }
  useEffect(() => {
    dispatchAnalyticsEvent('HOME', 'T1');
  }, []);

  useEffect(() => {
    if (!pageContent) setContent('img');
    else setContent(pageContent.carrossel);
  }, [pageContent]);

  const handleSendDataDispatchAnalytics = productsNames => dispatchAnalyticsEvent('HOME', 'A9', [productsNames]);

  return (
    <RecaptchaProvider>
      <Structural
        subtitle="Uma seleção **Itaú** dos melhores **planos** e **seguros**."
        auxText="Tudo em um só lugar para o seu bem-estar :)"
        auxTextLabel="Tudo em um só lugar para o seu bem estar"
        asideItems={content && ChooseBanner()}
        bigSubtitle
      >
        <PartnersSection />
        <ProductShowcaseSection />
        {remoteConfig.ENABLE_LEAD_HOME &&
            <LazyLoad>
            <ContactFormSection handleSendDataDispatchAnalytics={handleSendDataDispatchAnalytics} />
          </LazyLoad>}
      </Structural>
    </RecaptchaProvider>
  );
}
